import { UserTicket } from 'queries/admin-users/admin-user-tickets';
import { useMemo } from 'react';

const useCalculateInvoice = (userTickets: UserTicket[] | undefined) => {
  const invoice = useMemo(() => {
    let paidAmount = 0;
    let unpaidAmount = 0;
    if (userTickets) {
      userTickets.forEach((t) => {
        if (t.isPaid) paidAmount += t.basePriceSek;
        else unpaidAmount += t.basePriceSek;
      });
    }
    return [paidAmount, unpaidAmount];
  }, [userTickets]);

  return invoice;
};

export default useCalculateInvoice;
