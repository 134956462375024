import React from 'react';

interface Props {
  unpaidAmount: number;
}

const PaidOrUnpaidText: React.FC<Props> = ({ unpaidAmount }) => {
  if (unpaidAmount > 0)
    return (
      <>
        Please remember to pay for your events.
        <br />
        Unpaid amount: {unpaidAmount} SEK
      </>
    );
  return <>You&apos;re good to go! You have nothing to pay for</>;
};

export default PaidOrUnpaidText;
