import React, { useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import Dialog from 'components/dialog/Dialog';
import useEventFilter from 'components/eventFilter/useEventFilter';
import useIsMobile from 'hooks/useIsMobile';
import useProjectId from 'hooks/useProjectId';
import { getEventGroupsQuery } from 'queries/event/user-event-groups';
import { getEventsQuery } from 'queries/event/user-events';
import { getUserTicketsQuery } from 'queries/event/user-tickets';
import { UserTicketWithFirst, classifyEventList, filterEventList } from 'utils/eventFilter';
import TicketPageDescription from 'components/ticketPageDescription/TicketPageDescription';
import SendTicketContainer from './containers/SendTicket';
import useCalculateInvoice from './hooks/useCalculateInvoice';
import useRemoveBooking from './hooks/useRemoveBooking';
import Content from '/components/content/Content';
import StartEventDivider from '/components/eventCard/StartEventDivider';
import { EventStatus, TYPE, UserEventCard } from '/components/eventCard/UserEventCard';
import EventFilterController from '/components/eventFilter/EventFilterController';
import EventSearch from '/components/eventSearch/EventSearch';
import Footer from '/components/footer/Footer';
import PaymentBox from '/components/paymentBox/PaymentBox';

/**
 * Renders the My Tickets page
 */
const Tickets: React.FC = () => {
  const isMobile = useIsMobile();
  const projectId = useProjectId();
  const { data: events } = useQuery(getEventsQuery(projectId));
  const { data: userTickets } = useQuery(getUserTicketsQuery(projectId));
  const { data: eventGroups } = useQuery(getEventGroupsQuery(projectId));
  const invoice = useCalculateInvoice(userTickets);
  const eventFilter = useEventFilter();
  const removeBooking = useRemoveBooking();
  const bookedEventIds = userTickets ? userTickets.map((ticket) => ticket.eventId) : [];

  const [selectedBookingId, setSelectedBookingId] = useState<number | null>(null);
  const [isTransferDialogOpen, setIsTransferDialogOpen] = useState(false);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  /**
   * filtered events
   */
  const classified = classifyEventList(
    filterEventList(
      userTickets || [],
      eventFilter.eventFilter,
      bookedEventIds,
      [],
      eventGroups || [],
      eventFilter.search
    ),
    true
  ) as UserTicketWithFirst[];

  const listOfCategories = (eventId: number) => {
    if (!eventGroups) return [];

    const categoryNames: string[] = [];
    eventGroups.forEach((eventGroup) => {
      if (eventGroup.eventIDs.includes(eventId)) categoryNames.push(eventGroup.name);
    });

    return categoryNames;
  };

  return (
    <>
      <Dialog
        isOpen={removeBooking.isDeleteDialogOpen}
        question="Are you sure you want to delete your ticket?"
        buttonLabel="Delete"
        confirmOnClick={removeBooking.confirmRemoveBooking}
        cancelOnClick={removeBooking.closeRemoveBooking}
        buttonDisable={removeBooking.isLoading}
        buttonVariant="contained"
      />
      <Content
        header={
          userTickets && userTickets.length > 0 ? (
            <>
              <PaymentBox unpaidAmount={invoice[1]} />
              <TicketPageDescription />
            </>
          ) : (
            <TicketPageDescription />
          )
        }
        sidebarComponent={
          !isMobile ? (
            <EventFilterController
              eventFilter={eventFilter}
              events={events || []}
              tickets={userTickets || []}
              eventGroups={eventGroups || []}
              filterOverlay={isOverlayOpen}
              closeOverlay={() => setIsOverlayOpen(false)}
            />
          ) : undefined
        }
      >
        <EventSearch openOverlay={() => setIsOverlayOpen(true)} setEventSearch={eventFilter.setSearch} />
        <div>
          {classified.map((classifiedItem) => {
            const item = classifiedItem.data;
            return (
              <div key={`event--${item.eventId}`}>
                {classifiedItem.first && <StartEventDivider date={item.startDatetime} />}
                <UserEventCard
                  title={item.displayTitle}
                  isMobile={isMobile}
                  state={TYPE.TICKET as EventStatus}
                  location={item.location}
                  attendance={item.attendanceLimit}
                  price={item.basePriceSek}
                  startTime={new Date(item.startDatetime)}
                  endTime={new Date(item.endDatetime)}
                  transferEndTime={new Date(item.swapEndDatetime)}
                  description={item.description}
                  categories={listOfCategories(item.eventId)}
                  booking={false}
                  handleButton={() => removeBooking.tryRemoveBooking(item.bookingId)}
                  handleTransfer={() => {
                    setSelectedBookingId(item.bookingId);
                    setIsTransferDialogOpen(true);
                  }}
                  buttonDisabled={new Date() > new Date(Date.parse(item.unbookingEndDatetime))}
                />
              </div>
            );
          })}
        </div>
      </Content>
      <SendTicketContainer
        bookingId={selectedBookingId}
        isOpen={isTransferDialogOpen}
        closeModal={() => setIsTransferDialogOpen(false)}
      />
      <Footer />
    </>
  );
};

export default Tickets;
