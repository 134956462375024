import useDeleteBooking from 'queries/event/useDeleteBooking';
import { useState } from 'react';

const useRemoveBooking = () => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [bookingId, setBookingId] = useState<number | null>(null);

  const removeBookingMutation = useDeleteBooking(() => {
    setBookingId(null);
    setIsDeleteDialogOpen(false);
  });

  const tryRemoveBooking = (bid: number) => {
    setBookingId(bid);
    setIsDeleteDialogOpen(true);
  };

  const closeRemoveBooking = () => {
    setBookingId(null);
    setIsDeleteDialogOpen(false);
  };

  const confirmRemoveBooking = () => {
    removeBookingMutation.mutate(bookingId);
  };

  return {
    isLoading: removeBookingMutation.isPending,
    isDeleteDialogOpen,
    tryRemoveBooking,
    closeRemoveBooking,
    confirmRemoveBooking,
  };
};

export default useRemoveBooking;
