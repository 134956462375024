import { useMutation, useQueryClient } from '@tanstack/react-query';
import useOpenFeedback from 'hooks/useOpenFeedback';
import useProjectId from 'hooks/useProjectId';
import * as Backend from 'queries/BackendRequest';

const useDeleteBooking = (onSettled: () => void) => {
  const queryClient = useQueryClient();
  const feedback = useOpenFeedback();
  const projectId = useProjectId();

  return useMutation({
    mutationFn: async (bookingId: number | null) => {
      if (!bookingId) return;
      await Backend.request('delete', `/user/ticket/${bookingId}`);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['getUserTicketsQuery', projectId] });
      feedback.openSuccess(`Success: Ticket removed!`);
    },
    onSettled,
  });
};

export default useDeleteBooking;
