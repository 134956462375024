import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Typography } from '@mui/material';

interface Props {
  isMobile?: boolean;
  title?: string;
  description?: string;
  displayClose?: boolean;
  closeModal?: () => void;
}

const SubpageTitleText: React.FC<Props> = ({
  isMobile = false,
  title = '',
  description = '',
  displayClose = false,
  closeModal = () => null,
}) => {
  const renderCloseButton = (): React.ReactNode => {
    if (!isMobile && displayClose) {
      return (
        <IconButton onClick={closeModal} size="large">
          <CloseIcon />
        </IconButton>
      );
    }

    return <></>;
  };

  return (
    <div style={{ display: 'flex', marginTop: '20px', marginBottom: '22px' }}>
      <div style={{ flex: 1 }}>
        <Typography variant={isMobile ? 'h6' : 'h5'}>{title}</Typography>
        <Typography variant="body2" color="textSecondary">
          {description}
        </Typography>
      </div>
      {renderCloseButton()}
    </div>
  );
};

export default SubpageTitleText;
