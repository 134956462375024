import { Divider } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import useIsMobile from 'hooks/useIsMobile';
import useTransferTicket from '../../../queries/user/useTransferTicket';
import Dialog from '/components/dialog/Dialog';
import ButtonComponent from '/components/form/Button';
import SubpageModal from '/components/subpageModal/SubpageModal';
import SubpageTitleText from '/components/subpageModal/SubpageTitleText';
import EmailInput from '/containers/forms/EmailInput';
import LastNameInput from '/containers/forms/LastNameInput';

interface SendTicketContainerProps {
  bookingId: number | null;
  isOpen: boolean;
  closeModal: () => void;
}

interface FormValues {
  lastName: string;
  email: string;
}

const SendTicketContainer: React.FC<SendTicketContainerProps> = ({ bookingId, isOpen, closeModal }) => {
  const isMobile = useIsMobile();
  const [dialogOpen, setDialogOpen] = useState(false);
  const { handleSubmit, control } = useForm<FormValues>({ mode: 'all' });

  const onSubmitCheck = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
    closeModal();
  };

  const transferTicket = useTransferTicket(closeDialog, () => {
    setDialogOpen(false);
  });

  const onSubmit = (data: { lastName: string; email: string }) => {
    if (!bookingId) return;
    const { lastName, email } = data;
    transferTicket.mutate({ bookingId, lastName, email });
  };

  if (!bookingId) return <></>;

  return (
    <SubpageModal
      hideHeader={!isMobile}
      title="Ticket Transfer"
      maxWidth={918}
      isOpen={isOpen}
      reset={() => null}
      close={closeModal}
      hideReset
      variant="thick"
    >
      <SubpageTitleText
        isMobile={isMobile}
        title="Ticket Transfer"
        description="Transfer your ticket to another user and remove you as the ticket holder. This action cannot be undone."
        closeModal={closeModal as any}
        displayClose
      />

      {!isMobile && <Divider />}

      <SubpageTitleText
        isMobile={isMobile}
        title="Recipient"
        description={`
          Please enter the information of the user you want to transfer the ticket to. 
          Note that the e-mail has to be the registered e-mail adress the user signed up with.
        `}
      />

      <LastNameInput control={control} />
      <EmailInput control={control} />
      <ButtonComponent click={handleSubmit(onSubmitCheck)} label="Confirm" fullWidth disabled={undefined} />
      <Dialog
        title="Transfer Ticket?"
        question="I confirm the receiver has agreed on the transfer and I understand that this action cannot be undone."
        buttonLabel="Confirm"
        confirmOnClick={handleSubmit(onSubmit)}
        cancelOnClick={() => setDialogOpen(false)}
        isOpen={dialogOpen}
      />
    </SubpageModal>
  );
};

export default SendTicketContainer;
