import { useMutation, useQueryClient } from '@tanstack/react-query';
import useOpenFeedback from 'hooks/useOpenFeedback';
import useProjectId from 'hooks/useProjectId';
import { ErrorResponse } from 'types/types';
import * as Backend from '../BackendRequest';

interface MutationProps {
  bookingId: number;
  lastName: string;
  email: string;
}

const useTransferTicket = (onDone: () => void, onFail: () => void) => {
  const feedback = useOpenFeedback();
  const queryClient = useQueryClient();
  const projectId = useProjectId();
  const transferTicket = useMutation({
    mutationFn: async ({ bookingId, lastName, email }: MutationProps) => {
      await Backend.request('POST', `/user/booking/${bookingId}/transfer`, {}, { lastName, email });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['getUserTicketsQuery', projectId] });
      feedback.openSuccess('Ticket successfully transferred');
      onDone();
    },
    onError: async (error: ErrorResponse) => {
      await queryClient.invalidateQueries({ queryKey: ['getUserTicketsQuery', projectId] });
      const errorMessage = error?.response?.data?.error?.message;
      feedback.openError(`Error: Failed to transfer ticket (${errorMessage})`);
      onFail();
    },
  });

  return transferTicket;
};

export default useTransferTicket;
