import React from 'react';

import { styled } from '@mui/material';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import useIsMobile from 'hooks/useIsMobile';
import PaidOrUnpaidText from './PaidOrUnpaidText';

const RootCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'paidAll',
})<{ paidAll: boolean }>(({ theme, paidAll }) => ({
  padding: '8px',
  color: 'white',
  textAlign: 'center',
  backgroundColor: paidAll ? theme.palette.success.main : theme.palette.warning.main,
  [theme.breakpoints.up('md')]: {
    padding: '16px',
  },
}));

interface Props {
  unpaidAmount: number;
}

const PaymentBox: React.FC<Props> = ({ unpaidAmount }) => {
  const isMobile = useIsMobile();
  const paidAll = unpaidAmount === 0;
  const textVariant = isMobile ? 'body2' : 'body1';

  return (
    <>
      <RootCard paidAll={paidAll}>
        <Typography variant={textVariant}>
          <PaidOrUnpaidText unpaidAmount={unpaidAmount} />
        </Typography>
      </RootCard>
    </>
  );
};

export default PaymentBox;
